import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box, Text } from "../Core";
import imgL1Logo from "../../assets/image/svg/netomnia-logo-white.svg";
import Link from "gatsby-link";
import GlobalContext from "../../context/GlobalContext";

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  .white-text {
    color: rgba(255, 255, 255, 0.75) !important;
  }
  .icon {
    color: rgba(255, 255, 255, 0.75) !important;
  }
  li {
    line-height: 2.25;

    a {
      color: rgba(255, 255, 255, 0.75) !important;

      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const CopyRightArea = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.75) !important;
  padding: 15px 0;

  p {
    color: rgba(255, 255, 255, 0.75) !important;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;

    a {
      color: rgba(255, 255, 255, 0.75) !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;

      &:visited {
        text-decoration: none;
      }

      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const StyledBox = styled(Box)`
  background: rgb(25, 25, 27);
  position: relative;
  h4 { color: rgba(255, 255, 255, 0.75) !important; }
`;

const StyledSpan = styled.span`
  @media (max-width: 768px) {
    display: block;
  }
`;

const FooterText = styled(Text)`
    color: rgba(255, 255, 255, 0.75) !important;
    font-size: 1rem;
    line-height: 2.25;
    font-weight: 400;
    margin-top: 24px;
`;

const year = new Date().getFullYear();

const SocialIcons = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;

    a {
        color: rgba(255, 255, 255, 0.75) !important;
        font-size: 25px;
        transition: 0.4s;
        padding: 0 3px;
        margin: 0 2.5px;

        &:visited {
            text-decoration: none;
        }

      &:hover {
        text-decoration: none;
        color: ${({theme, color}) => theme.colors.secondary} !important;

        .path1:before {
          color: ${({theme, color}) => theme.colors.secondary} !important;
          transition: 0.4s;
        }
      }

      .path1:before {
        color: rgba(255, 255, 255, 0.75) !important;
        transition: 0.4s;
      }
    }
`;

const Footer = ({ isDark = true }) => {
    const gContext = useContext(GlobalContext);
    return (
        <div className={gContext.footerMinimized ? "footer-wrapper footer-minimized" : "footer-wrapper"}>
            <StyledBox>
                <Container>
                    <Box
                        css={`
                          padding: 80px 0 60px;
                        `}
                    >
                        <Row className="justify-content-center">
                            <Col sm="12" lg="4" className="mx-auto text-center text-lg-left">
                                <a href="/" target="_self">
                                    <img alt="" src={imgL1Logo} width={150}/>
                                </a>
                                <FooterText>
                                    Unleash your potential with Ultrafast Full Fibre broadband across the UK.
                                </FooterText>
                            </Col>
                            {
                            gContext.footerMinimized ?
                                <></>
                                :
                                <Col lg="8" md="8" className="mt-5 mt-lg-0">
                                    <Row>
                                        <Col xs="6" lg="3">
                                            <div className="mb-5 mb-lg-4">
                                                <TitleStyled
                                                    variant="card"
                                                    color={isDark ? "light" : "dark"}
                                                >
                                                    Company
                                                </TitleStyled>
                                                <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                                                    <li>
                                                        <Link to="/about-us/">
                                                            About us
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/contact/">
                                                            Contact
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/team/">
                                                            Team
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/legal/">
                                                            Legal
                                                        </Link>
                                                    </li>
                                                </UlStyled>
                                            </div>
                                        </Col>
                                        <Col xs="6" lg="3">
                                            <div className="mb-5 mb-lg-4">
                                                <TitleStyled
                                                    variant="card"
                                                    color={isDark ? "light" : "dark"}
                                                >
                                                    Useful links
                                                </TitleStyled>
                                                <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                                                    <li>
                                                        <Link to="/broadband/">
                                                            Broadband
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/careers/">
                                                            Careers
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/areas/">
                                                            Areas
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/faqs/">
                                                            FAQs
                                                        </Link>
                                                    </li>
                                                </UlStyled>
                                            </div>
                                        </Col>
                                        <Col xs="6" lg="6" className="social-icons-wrapper">
                                            <SocialIcons>
                                                <li>
                                                    <a href="https://www.facebook.com/netomnia" target="_blank" rel="noreferrer">
                                                        <i className="icon icon-logo-facebook"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://twitter.com/netomnia" target="_blank" rel="noreferrer">
                                                        <i className="icon iconn-twitter-x-seeklogocom-4-2">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/netomnia" target="_blank" rel="noreferrer">
                                                        <i className="icon icon-logo-linkedin"></i>
                                                    </a>
                                                </li>
                                            </SocialIcons>
                                        </Col>
                                    </Row>
                                </Col>
                            }
                        </Row>
                    </Box>
                    <CopyRightArea dark={isDark ? 1 : 0}>
                        <Row className="align-items-center">
                            <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                                <p>Unit H, The Courtyard, Tewkesbury Business Park, Tewkesbury, Gloucestershire GL20 8GD</p>
                            </Col>
                            <Col sm="6" className="text-sm-right text-center">
                                <p>&copy; { year } Netomnia, <StyledSpan>All Rights Reserved.</StyledSpan></p>
                            </Col>
                        </Row>
                    </CopyRightArea>
                </Container>
            </StyledBox>
        </div>
    );
};

export default Footer;
