import React from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import { Section, Text, Button, Title } from "../components/Core";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { navigate } from 'gatsby';
import heroImg from "./../assets/image/jpeg/hero-pia.jpg";
import piaBackground from './../assets/image/jpeg/pia-background.jpg';
import piaAbout from "./../assets/image/png/pia-about.png";
import icon1 from './../assets/image/png/pia-icon-1.png';
import icon2 from './../assets/image/png/pia-icon-2.png';
import icon3 from './../assets/image/png/pia-icon-3.png';
import icon4 from './../assets/image/png/pia-icon-4.png';
import icon5 from './../assets/image/png/pia-icon-5.png';
import icon6 from './../assets/image/png/pia-icon-6.png';
import caretDown from './../assets/image/png/caret-down.png';
import styled from "styled-components";

const Pia = () => {

    const AbsoluteTitle = styled(Title)`
        position: absolute;
        color: #fff;
        top: 120px;
        left: 15px;
        @media (min-width: 576px) { 
            top: 35%;
            left: calc((100vw - 525px) / 2);
        }
        @media (min-width: 768px) { 
            top: 35%;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 35%;
            left: calc((100vw - 945px) / 2);
        }
        @media (min-width: 1200px) {
            top: 35%;
            left: calc((100vw - 1125px) / 2);
        }
    `;

    const NarrowWrapper = styled.div`
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 20px 0 100px 0;
        p {
            color: #fff;
            text-align: left;
            margin-bottom: 10px;
        }
        @media (max-width: 1400px) {
            width: 100%;
        }
        @media (max-width: 991px) {
            margin-bottom: 50px;
        }
        padding-left: 15px;
        @media (min-width: 576px) { 
            padding-left: calc((100vw - 525px) / 2);
            padding-right: calc((100vw - 525px) / 2);
            p {
                font-size: 28px;
            }
        }
        @media (min-width: 768px) { 
            padding-left: calc((100vw - 705px) / 2);
            padding-right: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            padding-left: calc((100vw - 945px) / 2);
            padding-right: calc((100vw - 945px) / 2);
        }
        @media (max-width: 991px) { 
            padding-top: 0;
            padding-bottom: 40px;
        }
        @media (min-width: 1200px) {
            padding-left: calc((100vw - 1125px) / 2);
            padding-right: 0;
        }
    `;

    const StyledSection = styled(Section)`
        background: url(${piaBackground});
        background-repeat: no-repeat;
        background-size: 39%;
        background-position: 100% 0;
        background-color: #fdf5f9;
    `;

    const StyledSubtitle = styled.p`
        font-weight: 100;
        font-size: 1.8rem;
        line-height: 1.25;
        letter-spacing: -0.56px;
        margin-top: 20px;
        color: #d9007e !important;
    `;

    const SmallParagraph = styled(Text)`
        font-size: 18px;
        line-height: 30px;
    `;

    const FeatureContent = styled(Text)`
        line-height: 1.25;
        font-weight: 600;
        color: #000;
        font-size: 1.5em;
        margin-top: 15px;
    `;

    const HeroImg = styled.img`
        filter: brightness(0.8);
    `;

    return (
        <>
            <Seo page="wayleaves" />
            <div className="hero-wayleaves">
                <HeroImg src={heroImg} className="w-100 mt-10" />
                <AbsoluteTitle>PIA</AbsoluteTitle>
            </div>
            <PageWrapper>
                <Row className="justify-content-center text-center dark-bg">
                    <Col xl="7">
                        <NarrowWrapper>
                            <Text>
                                We are here to help unleash human potential
                                by upgrading the UK’s broadband connectivity.
                                Leveraging PIA (Physical Infrastructure Access) wherever
                                possible, has enabled our 10Gbps-capable
                                network to scale across the country.
                            </Text>
                            <Text>
                                It has unlocked <Link className="pink-color" to="/insights/what-is-fttp-broadband">FTTP broadband</Link> for hundreds of thousands of residents
                                in the UK, with more still to come.
                            </Text>
                            <Text>
                                This means that new opportunities are
                                being accessed by those who thought
                                it would never be possible.
                            </Text>
                        </NarrowWrapper>
                    </Col>
                    <Col xl="1"></Col>
                    <Col xl="4" className="flex-end">
                        <img src={piaAbout} className="w-100 first-img mt-5 pt-5" />
                    </Col>
                </Row>
                <StyledSection>
                    <Container>
                        <Row>
                            <Col xs="12" lg="9" className="mb-5">
                                <StyledSubtitle>
                                    PIA (Physical Infrastructure Access) is Openreach
                                    product/infrastructure that allows other network
                                    builders to use the existing paths to rollout their
                                    own fibre network.
                                </StyledSubtitle>
                                <StyledSubtitle>
                                    Utilising this wherever possible, means that we don’t
                                    have a need to implement our own infrastructure.
                                    This means less disruption, less noise and less
                                    mess when deploying our network.
                                </StyledSubtitle>
                            </Col>
                        </Row>

                        <hr className="mt-5" />

                        <Title variant="news" className="text-center mt-5 pt-5">Why PIA is so important</Title>
                        <Row className="justify-content-center text-center">
                            <Col lg="6" className="mt-5">
                                <img src={icon1} className="mt-3" alt="Netomnia Multiple devices" height={80} />
                                <FeatureContent>Cost Savings for Efficiency</FeatureContent>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        By making use of existing physical infrastructure,
                                        the initial costs are reduced significantly when rolling
                                        out our fibre network. It ensures that resources are
                                        allocated efficiently, translating to a more
                                        cost-effective network build.
                                    </SmallParagraph>
                                </div>
                            </Col>
                            <Col lg="6" className="mt-5">
                                <img src={icon2} className="mt-3" alt="Netomnia Multiple devices" height={80} />
                                <FeatureContent>Rapid Deployment for Immediate Access</FeatureContent>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Use of PIA enables a quicker deployment of the
                                        fibre broadband network. Instead of waiting to construct
                                        new equipment, we can swiftly upgrade existing
                                        infrastructure, providing residents and business owners
                                        with faster access to high-speed broadband services.
                                    </SmallParagraph>
                                </div>
                            </Col>
                            <Col lg="6" className="mt-5">
                                <img src={icon3} className="mt-3" alt="Netomnia Multiple devices" height={80} />
                                <FeatureContent>Environmental Responsibility for Community Appeal</FeatureContent>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Reusing existing infrastructure aligns with our
                                        environmental sustainability goals. Fibre networks
                                        are also more sustainable to run than their copper
                                        network counterparts.
                                    </SmallParagraph>
                                </div>
                            </Col>
                            <Col lg="6" className="mt-5">
                                <img src={icon4} className="mt-3" alt="Netomnia Multiple devices" height={80} />
                                <FeatureContent>Enhanced Community Relations for Positive Impact</FeatureContent>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Leveraging PIA contributes to positive community
                                        relations by minimising the disruptions commonly
                                        associated with new construction.
                                    </SmallParagraph>
                                    <SmallParagraph className="mt-3">
                                        This approach is particularly important when deploying
                                        networks in residential areas.
                                    </SmallParagraph>
                                    <SmallParagraph className="mt-3">
                                        We prioritise smoother and more positive relationships
                                        with the communities we serve. When we deploy our
                                        network, we always build with residents in mind.
                                    </SmallParagraph>
                                </div>
                            </Col>
                            <Col lg="6" className="mt-5">
                                <img src={icon5} className="mt-3" alt="Netomnia Multiple devices" height={80} />
                                <FeatureContent>Scalability for Future-Proofing</FeatureContent>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        Utilising existing infrastructure offers a scalable
                                        foundation for future expansion.
                                        This ensures that the network can easily adapt
                                        to increasing demand and evolving market needs.
                                        It is vital that a broadband connection can grow
                                        with your customer’s requirements, providing a
                                        reliable and future-proofed digital service.
                                    </SmallParagraph>
                                </div>
                            </Col>
                            <Col lg="6" className="mt-5">
                                <img src={icon6} className="mt-3" alt="Netomnia Multiple devices" height={80} />
                                <FeatureContent>Connecting Premises across the UK</FeatureContent>
                                <div className="w-80 centered">
                                    <SmallParagraph className="mt-3">
                                        We are here to provide access to a revolutionary broadband
                                        connection to as many UK residents as possible.
                                    </SmallParagraph>
                                    <SmallParagraph className="mt-3">
                                        Harnessing the power of the internet in today’s world can
                                        truly be lifechanging. Our network can enable your customers
                                        to start leveraging the power that the internet holds.
                                    </SmallParagraph>
                                    <SmallParagraph className="mt-3">
                                        Online grocery shopping, remote working, smart homes
                                        and so much more can be accessed because of the power
                                        our full fibre network holds.
                                    </SmallParagraph>
                                </div>
                            </Col>
                        </Row>
                        <Col lg="12" className="mt-5 pt-5 text-center">
                            <Button
                                style={{
                                    background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                    border: 'none',
                                    width: 'auto'
                                }}
                                className="btn-red hvr-bounce-to-left"
                                onClick={() => navigate('/contact/')}
                            >
                                Get in touch
                            </Button>
                        </Col>
                    </Container>
                </StyledSection>
            </PageWrapper>
        </>
    )
}

export default Pia;