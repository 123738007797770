import React from "react";
import PageWrapper from "../../components/PageWrapper";
import styled from "styled-components";
import {Title, Section} from "../../components/Core";
import {Col, Container, Row} from "react-bootstrap";
import {graphql, Link, useStaticQuery} from "gatsby";
import { getProperty } from "../../utils/helperFn";
import Seo from "../../components/Seo/Seo";

const SmallerTitle = styled(Title)`
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: -1.2px;
  margin-bottom: 0;
  padding-bottom: 0;
  display: inline;
`

const LegalPage = () => {

    const data = useStaticQuery(graphql`
     query getAllLegalCategories {
      allStrapiLegalCategory {
        nodes {
          name
          slug
        }
      }
    }`)

    const legalCategories = getProperty(data, 'allStrapiLegalCategory');

    const renderLegalCategory = () => (
        legalCategories.nodes.map(node => (
            <Col sm={12} md={12} className="mb-0 mb-md-0 text-left">
                <Link to={node.slug + "/"}>
                    <SmallerTitle>{node.name}</SmallerTitle>
                </Link>
            </Col>
        ))
    )

    return (
        <div>
            <Seo page="legal"/>
            <PageWrapper footerDark>
                <Section className="pb-0 legal-page-wrapper">
                    <div className="pt-5" />
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="9">
                                <Title variant="hero" style={{ fontSize: '65px' }}>The fine print</Title>
                            </Col>
                        </Row>
                        <Row className="mt-0 pt-5 mt-3 text-center">
                            {renderLegalCategory()}
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </div>
    )
}

export default LegalPage