import React from "react";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import { Section, Text, MainTitle, Title } from "../components/Core";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import heroImg from "./../assets/image/png/hero-planning.jpg";
import planningAbout from "./../assets/image/png/planning-about.png";
import planningAboutBackground from "./../assets/image/jpeg/planning-about.jpg";

import planning1 from './../assets/image/png/planning-icon-1.png';
import planning2 from './../assets/image/png/planning-icon-2.png';
import planning3 from './../assets/image/png/planning-icon-3.png';
import planning4 from './../assets/image/png/planning-icon-4.png';

import planningBackground from './../assets/image/rebrand/circle-gradient-half.png';

import bottomMotive from './../assets/image/png/bottom-motive.png';

import styled from "styled-components";
import OutlinedLightButton from "../components/Core/OutlinedLightButton";

const Planning = () => {

    const AbsoluteTitle = styled(Title)`
        position: absolute;
        color: #fff;
        top: 40%;
        left: 15px;
        @media (min-width: 576px) { 
            top: 29%;
            left: calc((100vw - 525px) / 2);
        }
        @media (min-width: 768px) { 
            top: 24%;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 19%;
            left: calc((100vw - 945px) / 2);
        }

        @media (min-width: 1200px) {
            top: 35%;
            left: calc((100vw - 1125px) / 2);
        }
    `;

    const PinkTitle = styled(Title)`
        color: #db118b;
        margin-top: 100px;
        text-align: left;
        padding-left: 15px;
        @media (max-width: 991px) {
            margin-top: 20px;
        }
    `;

    const NarrowWrapper = styled.div`
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 250px 0;
        p {
            color: #fff;
            text-align: left;
            margin-bottom: 10px;
        }
        @media (max-width: 1400px) {
            width: 100%;
        }
        @media (max-width: 991px) {
            margin-bottom: 50px;
        }
        padding-left: 15px;
        @media (min-width: 576px) { 
            padding-left: calc((100vw - 525px) / 2);
            padding-right: calc((100vw - 525px) / 2);
            p {
                font-size: 28px;
            }
        }
        @media (min-width: 768px) { 
            padding-left: calc((100vw - 705px) / 2);
            padding-right: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            padding-left: calc((100vw - 945px) / 2);
            padding-right: calc((100vw - 945px) / 2);
        }

        @media (max-width: 991px) { 
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @media (min-width: 1200px) {
            padding-left: calc((100vw - 1125px) / 2);
            padding-right: 0;
        }
    `;

    const Box = styled.div`
        background-color: #fff;
        padding: 50px;
        border-radius: 50px;
        box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.20);
        @media (max-width: 991px) {
            padding: 25px;
            .first-img {
                margin-bottom: 25px;
            }
            .second-img {
                margin-top: 25px;
            }
        }
    `;

    const StyledImage = styled.img`
        border-radius: 20px;
        width: 100%;
    `;

    const StyledSubtitle = styled.h2`
        color: #000;
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 1;
        letter-spacing: -0.56px;
    `;

    const StyledSection = styled(Section)`
        background: #000 url(${planningBackground}) no-repeat;
        padding: 0;
    `;

    const GradientSection = styled(Section)`
        position: relative;
        background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
        padding: 100px 0 0 0;
        h2, p, a {
            color: #fff !important;
        }
        .container {
            position: relative;
            padding-bottom: 100px;
        }
    `;

    const AbsoluteImage = styled.img`
        position: absolute;
        bottom: 0;
        left: -5%;
    `;

    const StyledText = styled(Text)`
        position: absolute;
        color: #fff;
        top: 63%;
        left: 15px;
        font-size: 18px;
        line-height: 1.1;
        @media (min-width: 576px) { 
            line-height: 1.5;
            top: 44%;
            left: calc((100vw - 525px) / 2);
            font-size: 28px;
        }
        @media (min-width: 768px) { 
            top: 38%;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 32%;
            left: calc((100vw - 945px) / 2);
        }

        @media (min-width: 1200px) {
            top: 46%;
            left: calc((100vw - 1125px) / 2);
        }
        color: #fff !important;
    `;

    const AboutSection = styled(Section)`
        background: url(${planningAboutBackground});
        background-repeat: no-repeat;
        background-size: cover;
    `;

    return (
        <>
            <Seo page="planning" />
            <div className="hero-wayleaves">
                <img src={heroImg} className="w-100 mt-10" />
                <AbsoluteTitle>Planning</AbsoluteTitle>
                <StyledText>
                    Unlock lightning-fast FTTP<br />
                    broadband for UK homes faster<br />
                    with our precision-engineered<br />
                    XGS-PON network plan.
                </StyledText>
            </div>
            <PageWrapper>
                <StyledSection>
                    <Row className="justify-content-center text-center">
                        <Col lg="6">
                            <NarrowWrapper>
                                <Text>
                                    An accurate fibre network plan is crucial in
                                    scaling our FTTP infrastructure quickly. It
                                    enables our build teams to deploy fibre
                                    optic cable at pace and with accuracy
                                    without facing delays and issues.
                                </Text>
                                <Text className="mt-3">
                                    That is why, by prioritising the foundation
                                    of our XGS-PON network, we are able to
                                    unlock FTTP broadband for as many
                                    homes across the UK as possible.
                                </Text>
                            </NarrowWrapper>
                        </Col>
                        <Col lg="6" className="flex-end">
                            <img src={planningAbout} className="w-100 first-img"/>
                        </Col>
                    </Row>
                </StyledSection>
                
                <AboutSection>
                    <Container>
                        <MainTitle className="text-center pink-color">Our process</MainTitle>
                        <Row className="justify-content-center text-center">
                        <Col lg="6" className="mt-5">
                            <img src={planning1} alt="Netomnia Town Surveys" width={100}/>
                            <Title variant="news" className="mt-4">Town Surveys</Title>
                            <div className="w-80 centered">
                                <Text className="mt-3">
                                    Before starting our <Link to="/insights/what-is-fibre-network-planning/">fibre network planning</Link> within
                                    our software, we assess the towns that we
                                    rollout in.
                                </Text>
                                <Text className="mt-3">
                                    Our planning team carry out their due diligence
                                    ensuring that future workflow can be efficient and
                                    as economical as possible.
                                </Text>
                                <Text className="mt-3">
                                    By assessing proposed routes and aligning this
                                    with desktop surveys, road access information and
                                    Openreach data, it paints a holistic picture that
                                    allows for a speedy infrastructure design.
                                </Text>
                            </div>
                            
                        </Col>
                        <Col lg="6" className="mt-5">
                            <img src={planning2} alt="Netomnia Leveraging PIA" width={100}/>
                            <Title variant="news" className="mt-4">Leveraging PIA</Title>
                            <div className="w-80 centered">
                                <Text className="mt-3">
                                    Leveraging existing PIA (Physical Infrastructure
                                    Access) enables us to maintain rapid build
                                    progress. There is no need for highly disruptive
                                    civils work or lengthy build times.
                                </Text>
                                <Text className="mt-3">
                                    This also allows for a speedier planning process
                                    and enables us to unlock ultrafast fibre broadband
                                    for the masses quicker.
                                </Text>
                            </div>
                        </Col>
                        <Col lg="6" className="mt-5">
                            <img src={planning3} alt="Netomnia Utilising Technology" width={100}/>
                            <Title variant="news" className="mt-4">Utilising Technology</Title>
                            <div className="w-80 centered">
                                <Text className="mt-3">
                                    Utilising technology has been critical in scaling
                                    planning efforts. By streamlining the process of
                                    designing our network, it means that we can rollout
                                    and build our network more cost-effectively.
                                </Text>
                                <Text className="mt-3">
                                    It has enabled us to become the first alt-net to
                                    deploy in all 4 UK countries.
                                </Text>
                            </div>
                        </Col>
                        <Col lg="6" className="mt-5">
                            <img src={planning4} alt="Netomnia Netomnia Planning" width={100}/>
                            <Title variant="news" className="mt-4">Netomnia Planning</Title>
                            <div className="w-80 centered">
                                <Text className="mt-3">
                                    Our planning has been foundational when it comes
                                    to the rapid growth of Netomnia.
                                </Text>
                                <Text className="mt-3">
                                    Planning with speed and accuracy allows for
                                    greater collaboration between our teams. Our build
                                    teams can trust that the designs they receive have
                                    been surveyed and are designed for maximum
                                    efficiency.
                                </Text>
                                <Text className="mt-3">
                                    All of this is to help us rollout across the UK and
                                    reach over a million premises helping residents
                                    access a broadband connection that can be truly
                                    revolutionary.
                                </Text>
                            </div>
                        </Col>
                        </Row>
                    </Container>
                </AboutSection>
                
                <GradientSection>
                    <Container>
                        <Row>
                            <AbsoluteImage src={bottomMotive} />
                            <Col lg="6">
                                <Title variant="news">Find out more…</Title>
                                <Text>
                                    If you are looking to leverage our network to reach
                                    more customers or provide a broadband connection
                                    that is transformational, please do get in touch with
                                    us today and we will respond as quickly as we can.
                                </Text>
                            </Col>
                            <Col lg="6" className="align-top-end">
                                <Link to="/contact/">
                                    <OutlinedLightButton>Contact us</OutlinedLightButton>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>

            </PageWrapper>
        </>
    )
}

export default Planning;
