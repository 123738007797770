import React, { useRef, useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, navigate, useStaticQuery } from "gatsby";
import UKRegions from "../../assets/map/uk-regions/uk-regions";
import Cities from "../../assets/map/uk-regions/Cities";
import { RadioSVGMap } from "react-svg-map";
import {Section, Text, MainTitle, Title, Button} from "../../components/Core";
import { isMobile } from "react-device-detect"
import GlobalStats from "../cities/GlobalStats";
import { TownCard, MoreCardButton } from "../../components/TownCard/TownCard";
import "../../assets/map/map.scss";
import {getProperty} from "../../utils/helperFn";
import PageWrapper from "../../components/PageWrapper";
import CheckAvailability from "./CheckAvailability";
import GlobalContext from "../../context/GlobalContext";

const NetomniaRegions = (props) => {
    const gContext = useContext(GlobalContext)

    const listStyles = {
        listStyle: 'decimal',
        padding: 0,
        marginLeft: '50px',
    };

    const townStyles = {
        maxHeight:'460px',
        overflowY: 'scroll',
        overflowX: 'auto',
        paddingBottom: '5px'
    };

    const data = useStaticQuery(graphql`
       query getAvailableRegionsQuery {
         strapiAreasPage {
            title
            subtitle
          }
          allStrapiRegions(filter: {towns: {elemMatch: {id: {gt: 0}}}}) {
            edges {
              node {
                  id
                  Slug
                  Name
              }
            }
          }
          allStrapiTowns {
            edges {
              node {
                Name
                Slug
                Featured_image{
                    childImageSharp {
                        fluid(maxHeight: 80, quality: 85) {
                          ...GatsbyImageSharpFluid
                        }
                    }
                }
                region {
                    Name
                    Slug
                }
              }
            }
          }
          strapiPageHeadings(slug: {eq: "areas"}) {
                h1
            }
        }
    `)

    useEffect(() => {
        gContext.goResetRegistrationData()
    }, [])

    const [selectedLocationId, setSelectedLocationId] = useState(props.region ? props.region.Slug : 'north-east')
    const mapResultsRef = useRef()
    const pageElements = data.strapiAreasPage
    const netomniaRegions = data.allStrapiRegions.edges.map(strapiRegion => strapiRegion.node)
    const netomniaTowns = data.allStrapiTowns.edges.map(strapiTown => strapiTown.node)
    const headingsData = getProperty(data, 'strapiPageHeadings');
    const currentRegion = netomniaRegions.find(x => x.Slug === selectedLocationId);

    const allUkRegions = UKRegions.mapData.map(region => ({
        name: region.name,
        id: region.id,
        path: region.path,
    }))

    const map = {
        "label": "Map of UK Regions",
        "viewBox": "-679.3 146.4 327.7 370.6",
        "locations": allUkRegions
    }

    const handleMapClick = (id) => {
        if (netomniaTowns.find(town => town.region.Slug === id)) {
            setSelectedLocationId(id);
            props.isNew && navigate('/areas/' + id);
        }
    }

    const renderMapFilter = () => {

        const townsInRegion = netomniaTowns.filter(town => (
            town.region.Slug === selectedLocationId
        ))

        const renderTown = (town) => (
            <Col className="col-12 mt-3" key={town.Slug}>
                <TownCard
                    townImg={town.Featured_image}
                    title={town.Name}
                    link={town.Slug}
                    regionName={town.region.Name}
                ></TownCard>
            </Col>
        )


        return (
            <>
                <Row className="align-items-center justify-content-center mt-3" ref={mapResultsRef} style={props.isNew && townStyles}>
                    {
                        /* Limit number of towns to 5 */
                        townsInRegion.length > 4
                            ? (props.isNew ? townsInRegion.map(town => renderTown(town)) : townsInRegion.slice(0, 5).map(town => renderTown(town)))
                            : townsInRegion.map(town => renderTown(town))
                    }
                </Row>
                {!props.isNew && <MoreCardButton areaLink={selectedLocationId}/>}
            </>
        )
    }

    const getLocationClassName = (location) => {
        return netomniaRegions.find(x => x.Slug === location.id) ? 'svgregion active' : 'svgregion disabled'
    }
    return (
        <>
            <Section className="position-relative pt-5 pb-0">
                <Container>
                    {
                        !props.mapOnly && !props.isNew &&
                        <Row className="justify-content-center">
                            <Col sm={12} md={10} className="pb-3">
                                <div className="pt-5 mt-5">
                                    <MainTitle className="text-center">{headingsData.h1}</MainTitle>
                                    <Text className="mt-5 mb-5 text-center">
                                        {pageElements.subtitle}
                                    </Text>
                                    <Text className="mb-5 text-center text-">Click on the map to find out more about our
                                        plans.</Text>
                                </div>
                            </Col>
                        </Row>
                    }

                    <Row className="justify-content-between mb-0">
                        {props.isNew &&
                        <Col sm={12} md={8} lg={5}>
                            <Title>Fibre Broadband
                                in {(currentRegion && currentRegion.Name) ? currentRegion.Name : ''}</Title>
                            <Text>Our full fibre broadband network is in {props.region.towns.length} cities in
                                the {currentRegion && currentRegion.Name}.</Text>
                            <br />
                            <Text className="text-left">Enter your postcode to get faster fibre today!</Text>
                            <CheckAvailability 
                                hideLogo={true}
                                customTitle={""}
                                customSubtitle={""}
                            />
                        </Col>
                        }
                        <Col sm={12} md={4} lg={7} className="mt-3 mt-lg-4 interactive-map-wrapper">
                            <RadioSVGMap
                                map={map}
                                selectedLocationId={selectedLocationId}
                                locationClassName={getLocationClassName}
                                onChange={(e) => handleMapClick(e.id)}
                                childrenAfter={<Cities filterData={props.isNew ? selectedLocationId : null} />}
                            />
                        </Col>
                        {props.isNew && <div className="pt-5 w-100" />}
                        <Col sm={12} md={8} lg={5}>
                            {renderMapFilter()}
                        </Col>
                        {/* SAMO ZA NOVU STRANU */}
                        {props.isNew &&
                        <Col sm={12} md={4} lg={6}>
                            <Title>Benefits of Full Fibre <br/>Broadband in {(currentRegion && currentRegion.Name) ? currentRegion.Name : ''}</Title>
                            <Text>
                                <ul style={listStyles}>
                                    <li>Stress-free internet usage</li>
                                    <li>A connection that can handle the entire family</li>
                                    <li>Perfect quality video calls</li>
                                    <li>Seamless movie/TV streaming</li>
                                    <li>A reliable connection that won’t let you down</li>
                                    <li>Improved work efficiency and productivity</li>
                                </ul>
                            </Text>
                        </Col>
                        }
                    </Row>
                </Container>
                {props.isNew &&
                <Container>
                    <Row className="justify-content-center text-center mb-5 pt-5">
                        <Col lg={9}>
                            <Title>How To Switch</Title>
                            <Text>1.&nbsp;&nbsp;&nbsp;Check your availability on our website<br/>
                                2.&nbsp;&nbsp;&nbsp;If you’re live, visit our ISP partners and pick a package<br/>
                                3.&nbsp;&nbsp;&nbsp;We’ll come and install the new line and you’re good to go!
                            </Text>
                            <Button
                                style={{
                                    background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                    border: 'none',
                                    width: 'auto'
                                }}
                                className="btn-red hvr-bounce-to-left mt-4"
                                onClick={() => navigate('/check-availability/')}
                            >
                                Check Availability
                            </Button>
                        </Col>
                    </Row>
                </Container>
                }
            </Section>
            {
                !props.mapOnly && !props.isNew &&
                <Section className="pt-5 pb-0">
                    <GlobalStats />
                </Section>
            }

        </>
    )
}

export default NetomniaRegions;
