import React, { useContext, useEffect, useState } from 'react';
import { Section } from '../components/Core';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalContext from '../context/GlobalContext';
import FullScreenLoader from '../components/FullScreenLoader';
import Seo from '../components/Seo/Seo';
import CurrentProviderForm from '../components/Forms/CurrentProviderForm';
import ResidentialForm from '../components/Forms/ResidentialForm/ResidentialForm';
import CurrentContractForm from '../components/Forms/CurrentContractForm';
import { navigate } from 'gatsby';

/* GTM */
declare global {
  interface Window {
    dataLayer: any[];
  }
}

const RegisterPage = (props: any) => {
  const gContext: any = useContext(GlobalContext);

  const isOrder = gContext.goGetRegistrationData()?.SearchPremiseResults.properties['SalesStatus'] === 'ORDER';

  useEffect(() => {
    if (!gContext.goGetRegistrationData()?.SearchPremiseResults) {
      navigate('/')
    }

    const params = new URLSearchParams(props.location.search);
    const step = params.get('step');
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      registerStep: stepGuard(step),
    });
  }, [props.location.search]);

  const stepGuard = (step) => {
    return isOrder ? (step ? parseInt(step) : 1) : 3
  }

  return (
    <>
      <Seo page="register" featuredImageUrl={undefined} />
      <FullScreenLoader title="Processing registration ..." />
      <Section>
        <Container>
          {/* Step 1 - Select Current Provider */}
          {gContext.goGetRegistrationData()?.registerStep === 1 &&
            <>
              <Row className="justify-content-center">
                <Col lg={6} md={6} sm={12} className="text-center mb-3 mt-3">
                  <h4 className="mb-4 color-black">Who is your current provider?</h4>
                  <p className="color-black">If you are unsure, please select "other"</p>
                </Col>
              </Row>
              <CurrentProviderForm />
            </>
          }

          {/* Step 2 - Select Current contract status */}
          {gContext.goGetRegistrationData()?.registerStep === 2 &&
            <>
              <Row className="justify-content-center">
                <Col lg={6} md={6} sm={12} className="text-center mb-3 mt-3">
                  <h4 className="mb-4 color-black">Are you still in contract with your current provider?</h4>
                  <p className="color-black">Tell us more about your contract</p>
                </Col>
              </Row>
              <CurrentContractForm />
            </>
          }

          {/* Step 3 - Provide Personal Information */}
          {gContext.goGetRegistrationData()?.registerStep === 3 &&
            <>
              <Row className="justify-content-center">
                <Col lg={6} md={8} sm={12} className="text-center mt-3">
                  <div
                    className="text-center provider-text"
                    dangerouslySetInnerHTML={{
                      __html: `<h4 className="color-black">Please enter your details</h4><p className="color-black">Please enter your details below to check your homes eligibility</p>`,
                    }}
                  />
                </Col>
              </Row>
              <ResidentialForm />
            </>
          }
        </Container>
      </Section>
    </>
  );
};

export default RegisterPage;
