import React, { useRef, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import Seo from "./../../components/Seo";
import styled from "styled-components";
import BlogList from "../../sections/blog/BlogList";
import { graphql, useStaticQuery } from "gatsby";
import { TownCard, MoreCardButton } from "../../components/TownCard/TownCard";
import { Container, Row, Col } from "react-bootstrap";
import UKRegions from "../../assets/map/uk-regions/uk-regions";
import Cities from "../../assets/map/uk-regions/Cities";
import { RadioSVGMap } from "react-svg-map";
import {Section, Text, MainTitle, Title, Button} from "../../components/Core";

import number1 from '../../assets/image/rebrand/number-1.svg';
import number2 from '../../assets/image/rebrand/number-2.svg';
import number3 from '../../assets/image/rebrand/number-3.svg';
import number4 from '../../assets/image/rebrand/number-4.svg';
import number5 from '../../assets/image/rebrand/number-5.svg';
import number6 from '../../assets/image/rebrand/number-6.svg';
import CheckAvailability from "../../sections/index/CheckAvailability";
import areasSection from '../../assets/image/rebrand/areas-section.jpg';
import { navigate } from "gatsby";

const GraySection = styled(Section)`
    background-color: #eee;
`;

const AreasSection = styled(Section)`
    background: url(${areasSection});
`;

const WhiteBox = styled.div`
    background-color: #fff;
    border-radius: 20px;
    padding: 50px;
    @media (max-width: 991px) {
        padding: 20px;
    }
`;

const LandingPage = () => {

    const data = useStaticQuery(graphql`
        query allAreasNewsArticlesQuery {
            allStrapiNewsArticles(sort: {
                order: DESC,
                fields: backdate
            }) {
                edges {
                    node {
                        id
                        strapiId
                        backdate
                        published_at
                        title
                        content
                        slug
                        image_featured {
                            childImageSharp {
                                fluid(maxWidth: 400) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
            strapiAreasPage {
                title
                subtitle
            }
            allStrapiRegions(filter: {
                towns: {
                    elemMatch: {
                        id: {
                            gt: 0
                        }
                    }
                }
            }) {
                edges {
                    node {
                        id
                        Slug
                        Name
                    }
                }
            }
            allStrapiTowns {
                edges {
                    node {
                        Name
                        Slug
                        Featured_image {
                            childImageSharp {
                                fluid(maxHeight: 80, quality: 85) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        region {
                            Name
                            Slug
                        }
                    }
                }
            }
            strapiPageHeadings(slug: {
                eq: "areas"
            }) {
                h1
            }
        }
    `)

    const [selectedLocationId, setSelectedLocationId] = useState('north-east')
    const netomniaRegions = data.allStrapiRegions.edges.map(strapiRegion => strapiRegion.node)
    const netomniaTowns = data.allStrapiTowns.edges.map(strapiTown => strapiTown.node)
    const [currentRegion, setCurrentRegion] = useState(netomniaRegions.find(x => x.Slug === selectedLocationId)?.Name)
    const mapResultsRef = useRef()

    const allUkRegions = UKRegions.mapData.map(region => ({
        name: region.name,
        id: region.id,
        path: region.path,
    }))

    const map = {
        "label": "Map of UK Regions",
        "viewBox": "-679.3 146.4 327.7 370.6",
        "locations": allUkRegions
    }

    const handleMapClick = (id) => {
        if (netomniaTowns.find(town => town.region.Slug === id)) {
            setCurrentRegion(netomniaRegions.find(x => x.Slug === id)?.Name)
            setSelectedLocationId(id);
        }
    }

    const renderMapFilter = () => {

        const townsInRegion = netomniaTowns.filter(town => (
            town.region.Slug === selectedLocationId
        ))

        const renderTown = (town) => (
            <Col className="col-12 mt-3" key={town.Slug}>
                <TownCard
                    townImg={town.Featured_image}
                    title={town.Name}
                    link={town.Slug}
                    regionName={town.region.Name}
                ></TownCard>
            </Col>
        )

        return (
            <>
                <Row className="align-items-center justify-content-center" ref={mapResultsRef}>
                    {
                        townsInRegion.length > 4 ?
                            townsInRegion.slice(0, 5).map(town => renderTown(town)) :
                            townsInRegion.map(town => renderTown(town))
                    }
                </Row>
                <MoreCardButton areaLink={selectedLocationId}/>
            </>
        )
    }

    const getLocationClassName = (location) => {
        return netomniaRegions.find(x => x.Slug === location.id) ? 'svgregion active' : 'svgregion disabled'
    }

    const CheckAvailabilityBox = styled.div`
        border: 1px solid #c11888;
        border-radius: 20px;
        padding: 50px;
        box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.20);
        margin-top: 50px;
        @media (max-width: 991px) {
            padding: 20px;
        }
    `;

    return (
        <>
            <Seo page="areas"/>
            <PageWrapper>
                <Section className="position-relative">
                    <Container>
                        <Row>
                            <Col sm={12} lg={6} className="mt-3 mt-lg-4 interactive-map-wrapper">
                                <RadioSVGMap
                                    map={map}
                                    selectedLocationId={selectedLocationId}
                                    locationClassName={getLocationClassName}
                                    onChange={(e) => handleMapClick(e.id)}
                                    childrenAfter={<Cities filterData={null} />}
                                />
                            </Col>
                            <Col sm={12} lg={6}>
                                <MainTitle className="text-left">
                                    Fibre Broadband<br/>in {currentRegion}
                                </MainTitle>
                                <Text
                                    color="heading"
                                    as="h3"
                                    fontSize="1.6em"
                                    fontWeight={500}
                                    letterSpacing={-0.75}
                                    mb={0}
                                >
                                    Our full fibre broadband network
                                    is in 7 cities in the North East.
                                </Text>
                                <CheckAvailabilityBox>
                                    <Text
                                        color="#db118b"
                                        as="h3"
                                        fontSize="1.6em"
                                        lineHeight={1.2}
                                        fontWeight={600}
                                        letterSpacing={-0.75}
                                        mb={0}
                                        className="text-center"
                                    >
                                        Could you benefit from<br/>
                                        our fibre network?
                                    </Text>
                                    <Text
                                        color="#000"
                                        className="mt-3 text-center"
                                    >
                                        Enter your postcode to get
                                        faster fibre today!
                                    </Text>
                                    <CheckAvailability
                                        focusInput={false}
                                        hideLogo={true}
                                        customTitle={""}
                                        customSubtitle={""}
                                    />
                                </CheckAvailabilityBox>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <GraySection>
                    <Container>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Title variant="news">
                                    Benefits of Full<br />
                                    Fibre Broadband<br />
                                    in {currentRegion}
                                </Title>
                                <Text className="mt-1 align-start">
                                    <img src={number1} width={35}/><span className="ml-2">Stress-free internet usage</span>
                                </Text>
                                <Text className="mt-1 align-start">
                                    <img src={number2} width={35}/><span className="ml-2">A connection that can handle the entire family</span>
                                </Text>
                                <Text className="mt-1 align-start">
                                    <img src={number3} width={35}/><span className="ml-2">Perfect quality video calls</span>
                                </Text>
                                <Text className="mt-1 align-start">
                                    <img src={number4} width={35}/><span className="ml-2">Seamless movie/TV streaming</span>
                                </Text>
                                <Text className="mt-1 align-start">
                                    <img src={number5} width={35}/><span className="ml-2">A reliable connection that won’t let you down</span>
                                </Text>
                                <Text className="mt-1 align-start">
                                    <img src={number6} width={35}/><span className="ml-2">Improved work efficiency and productivity</span>
                                </Text>
                            </Col>
                            <Col sm={12} lg={6}>
                                {renderMapFilter()}
                            </Col>
                        </Row>
                    </Container>
                </GraySection>
                <AreasSection>
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <WhiteBox>
                                    <Title variant="news" color="#db118b">
                                        How to switch
                                    </Title>
                                    <Text className="mt-1 align-start">
                                        <img src={number1} width={35}/><span className="ml-2">Check your availability on our website</span>
                                    </Text>
                                    <Text className="mt-1 align-start">
                                        <img src={number2} width={35}/><span className="ml-2">If you’re live, visit our ISP partners and pick a package</span>
                                    </Text>
                                    <Text className="mt-1 align-start">
                                        <img src={number3} width={35}/><span className="ml-2">We’ll come and install the new line and you’re good to go!</span>
                                    </Text>
                                    <Button
                                        style={{
                                            background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                            border: 'none',
                                            width: 'auto'
                                        }}
                                        className="btn-red hvr-bounce-to-left mt-4"
                                        onClick={() => navigate('/check-availability/')}
                                    >
                                        Check Availability
                                    </Button>
                                </WhiteBox>
                            </Col>
                        </Row>
                    </Container>
                </AreasSection>
                <BlogList articles={data} enquiryHtml={""} page="news"/>
            </PageWrapper>
        </>
    );
};
export default LandingPage;
