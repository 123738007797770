import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Select, { components } from 'react-select';
import { Section } from "../../components/Core";
import { Location } from '@reach/router';
import PageWrapper from "../../components/PageWrapper";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

const FreshStreamWidget = () => {
    const [showFilters, setShowFilters] = useState(false);
    const [showFiltersButton, setShowFiltersButton] = useState(false);
    const [jobs, setJobs] = useState(null);
    const [filterJobs, setFilterJobs] = useState(null);
    const [filterSearch, setFilterSearch] = useState("");
    const [filterLocation, setFilterLocation] = useState({"label":"", "value":""});
    const [filterDepartment, setFilterDepartment] = useState({"label":"", "value":""});
    const [filterEmploymentType, setFilterEmploymentType] = useState({"label":"", "value":""});

    const [uniqueLocation, setUniqueLocation] = useState([]);
    const [uniqueDepartment, setUniqueDepartment] = useState([]);
    const [uniqueEmploymentType, setUniqueEmploymentType] = useState([]);

    const ControlComponent = (props) => <components.Control {...props} />;

    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth < 993){
                setShowFiltersButton(true);
                setShowFilters(false);
            }
            else {
                setShowFiltersButton(false);
                setShowFilters(true);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        fetch('https://substantialgroup.pinpointhq.com/jobs.json')
          .then(response => response.json())
          .then(result => {
            const youFibreJobs = sortByLocation(result.data?.filter(obj => obj?.division?.id === "1293" || obj?.division?.id === "1294" ));
            setJobs(youFibreJobs);
            setFilterJobs(youFibreJobs);
          })
          .catch(error => console.error('Error fetching data:', error));
        return () => {
            window.removeEventListener('resize', handleResize);
          };
      }, []); 

      useEffect(() => {    
        if(jobs){
            setUniqueLocation(['', ...[...new Set(jobs.map(obj => obj.location.name).sort())]]);
            setUniqueDepartment(['', ...[...new Set(jobs.map(obj => obj.department.name).sort())]]);
            setUniqueEmploymentType(['', ...[...new Set(jobs.map(obj => obj.employment_type_text).sort())]]);
        }
      }, [jobs]);
  
      useEffect(() => {
          if (filterJobs) {
              setFilterJobs(jobs.filter(item => 
                  item.title.toLowerCase().includes(filterSearch.toLowerCase())
                  && item.location.name.includes(filterLocation.value)
                  && item.department.name.includes(filterDepartment.value)
                  && item.employment_type_text.includes(filterEmploymentType.value)
              ));
          }
      }, [filterSearch, filterLocation, filterDepartment, filterEmploymentType]);

      const sortByLocation = (array) => {
        return array.sort((a, b) => {
          if (a.location.name < b.location.name) return -1;
          if (a.location.name > b.location.name) return 1;
          return 0;
        });
      };
  
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = "https://s3.eu-central-1.amazonaws.com/files-eu.freshteam.com/production/102939/attachments/25001870764/original/25000007989_widget.js?1680015666";
    //     script.async = true;
    //     document.body.appendChild(script);
    //     return () => {
    //         document.body.removeChild(script);
    //     }
    // }, []);

    return (
        <>
        {/* <Helmet>
            <script src='https://s3.eu-central-1.amazonaws.com/files-eu.freshteam.com/production/104526/attachments/25000303931/original/25000008663_widget.js?1642181397'></script>
        </Helmet>
        <PageWrapper footerDark>
            <Section className="freshstream-box">
                <Container>
                    <Row className="justify-content-center text-center">
                        <Col lg="12">
                            <div id='freshteam-widget'></div>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <div className="pt-5" />
        </PageWrapper> */}
        <Container>
            <Row className="justify-content-center text-center">
                <Col lg="12">
                    <div className="job-offers mt-5">
                            <div className="cell filter">
                                <input id="filterSearch" type="text" placeholder="Search job title" className="filterSearch" onChange={e => setFilterSearch(e.target.value)} />
                                <div className="search"><BsSearch /></div>
                            </div>
                            { showFilters && 
                            <div className="cell filter">
                                <Select
                                className="filterDropdown"
                                components={{ Control: ControlComponent }}
                                isSearchable
                                options={uniqueLocation.map(item => ({"label":item !== ''?item:'All locations', "value":item}))}
                                placeholder="Location"
                                onChange={option => {
                                    setFilterLocation(option)
                                }}
                                styles={{
                                    menuList: (baseStyles, state) => ({
                                    ...baseStyles,
                                    padding: '5px'
                                    }),
                                    option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isFocused ? '#fb3a4e' : '#ffffff',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    fontFamily: 'CircularStd, sans-serif',
                                    fontSize: '16px',
                                    color: state.isFocused ? '#ffffff' : '#000000'
                                    }),
                                }}
                                />
                            </div>
                            }
                            { showFilters &&             
                            <div className="cell filter">
                                <Select
                                className="filterDropdown"
                                components={{ Control: ControlComponent }}
                                isSearchable
                                options={uniqueDepartment.map(item => ({"label":item !== ''?item:'All departments', "value":item}))}
                                placeholder="Department"
                                onChange={option => {
                                    setFilterDepartment(option)
                                }}
                                styles={{
                                    menuList: (baseStyles, state) => ({
                                    ...baseStyles,
                                    padding: '5px'
                                    }),
                                    option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isFocused ? '#fb3a4e' : '#ffffff',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    fontFamily: 'CircularStd, sans-serif',
                                    fontSize: '16px',
                                    color: state.isFocused ? '#ffffff' : '#000000'
                                    }),
                                }}
                                />
                            </div>
                            }
                            { showFilters &&   
                            <div className="cell filter">
                                <Select
                                className="filterDropdown"
                                components={{ Control: ControlComponent }}
                                isSearchable
                                options={uniqueEmploymentType.map(item => ({"label":item !== ''?item:'All department types', "value":item}))}
                                placeholder="Department&nbsp;type"
                                onChange={option => {
                                    setFilterEmploymentType(option)
                                }}
                                styles={{
                                    menuList: (baseStyles, state) => ({
                                    ...baseStyles,
                                    padding: '5px'
                                    }),
                                    option: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: state.isFocused ? '#fb3a4e' : '#ffffff',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    fontFamily: 'CircularStd, sans-serif',
                                    fontSize: '16px',
                                    color: state.isFocused ? '#ffffff' : '#000000'
                                    }),
                                }}
                                />
                            </div>
                            }
                            { showFiltersButton && <span className="splitter" onClick={() => setShowFilters(!showFilters)}>{ showFilters ? "Hide filters": "Show filters" } { showFilters ? <BsChevronUp /> : <BsChevronDown /> }</span> }
                            <div className="splitter">&nbsp;</div>
                            <span className="cell header">Job title</span>
                            <span className="cell header">Location</span>
                            <span className="cell header">Department</span>
                            <span className="cell header">Employment type</span>                                                
                            {filterJobs && filterJobs.map((job, index) => (
                                <>
                                    <span className={`cell ${index%2?'even':'odd'}`} onClick={() => navigate(job.url)}><b>{job.title}</b></span>
                                    <span className={`cell ${index%2?'even':'odd'}`} onClick={() => navigate(job.url)}>{job.location.name}</span>
                                    <span className={`cell ${index%2?'even':'odd'}`} onClick={() => navigate(job.url)}>{job.department.name}</span>
                                    <span className={`cell ${index%2?'even':'odd'}`} onClick={() => navigate(job.url)}>{job.employment_type_text}</span>
                                </>
                            ))}
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    )
}


export default FreshStreamWidget;
