import React, { createRef, useEffect } from "react";
import Seo from "../components/Seo/Seo";
import { Section, Text, Button, MainTitle, Title } from "../components/Core";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import bottomMotive from './../assets/image/png/bottom-motive.png';
import { graphql, useStaticQuery, Link } from "gatsby";
import heroImg from "./../assets/image/jpeg/hero-service-delivery.jpg";

import serviceDeliveryAbout from "./../assets/image/jpeg/service-delivery-about.jpg";

import serviceDeliveryBg from "./../assets/image/jpeg/service-delivery-background.jpg";

import styled from "styled-components";
import OutlinedLightButton from "../components/Core/OutlinedLightButton";

import rightMotive from "./../assets/image/rebrand/circle-gradient-right.png";
import divider from "./../assets/image/png/separator.jpg";

import serviceImage1 from "./../assets/image/jpeg/service-image-1.jpg";
import serviceImage2 from "./../assets/image/jpeg/service-image-2.jpg";
import serviceImage3 from "./../assets/image/jpeg/service-image-3.jpg";
import serviceImage4 from "./../assets/image/jpeg/service-image-4.jpg";
import serviceImage5 from "./../assets/image/jpeg/service-image-5.jpg";
import serviceImage6 from "./../assets/image/jpeg/service-image-6.jpg";

import serviceIcon1 from "./../assets/image/png/service-icon-1.png";
import serviceIcon2 from "./../assets/image/png/service-icon-2.png";
import serviceIcon3 from "./../assets/image/png/service-icon-3.png";

import animation from "./../assets/animations/hero-main.json"
import lottie from "lottie-web"

const ServiceDeliveryPage = (props) => {

    const animationContainer = createRef()

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animation
        });
        return () => anim.destroy(); // optional clean up for unmounting
    }, []);

    const GradientSection = styled(Section)`
        position: relative;
        background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
        padding: 100px 0 0 0;
        h2, p, a {
            color: #fff !important;
        }
        .container {
            position: relative;
            padding-bottom: 100px;
        }
    `;

    const AbsoluteImage = styled.img`
        position: absolute;
        bottom: 0;
        left: -5%;
    `;

    const HeroImg = styled.img`
        filter: brightness(0.8);
    `;

    const AbsoluteTitle = styled(Title)`
        position: absolute;
        color: #fff;
        top: 110px;
        left: 15px;
        @media (min-width: 576px) { 
            top: 120px;
            left: calc((100vw - 525px) / 2);
        }
        @media (max-width: 768px) { 
            font-size: 1.8rem;
            line-height: 1.1;
        }
        @media (min-width: 768px) { 
            top: 120px;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 120px;
            left: calc((100vw - 945px) / 2);
        }

        @media (min-width: 1200px) {
            top: 35%;
            left: calc((100vw - 1125px) / 2);
        }
    `;

    const StyledSubtitle = styled.h2`
        font-weight: 100;
        font-size: 2.0rem;
        line-height: 1.25;
        letter-spacing: -0.56px;
    `;

    const DarkBg = styled(Section)`
        background-color: #000;
        padding: 0;
        .row {
            background-image: url(${rightMotive});
            background-repeat: no-repeat;
            background-position: right;
            background-size: 240px;
        }
        @media (max-width: 1200px) { 
            .first-img-wrapper { display: none; }
            h2 {
                margin-top: 50px;
            }
            p, h2 {
                padding-left: 15px;
                width: 100%;
                @media (min-width: 576px) { 
                    padding-left: calc((100vw - 525px) / 2);
                }
                @media (min-width: 768px) { 
                    padding-left: calc((100vw - 705px) / 2);
                }
                @media (min-width: 992px) { 
                    padding-left: calc((100vw - 945px) / 2);
                }
        
                @media (min-width: 1200px) {
                    padding-left: 0;
                }
            }
        }
    `;

    const StyledSection = styled(Section)`
        background-image: url(${serviceDeliveryBg});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        iframe {
            border: none;
        outline: none;
        box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.20);
        background: transparent;
        outline-color: transparent;
        }
    `;

    const Box = styled.div`
        border-radius: 25px;
        box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.20);
        text-align: center;
        min-height: 600px;
        overflow: hidden;
        margin-bottom: 30px;
        background-color: #fff;
        .box-wrapper {
            padding: 35px;
        }
    `;

    const SmallParagraph = styled(Text)`
        font-size: 18px;
        line-height: 30px;
    `;

    return (
        <div>
            <Seo title='Netomnia - Service Delivery' description='Netomnia - Service Delivery'/>
            <div className="hero-wayleaves">
                <HeroImg src={heroImg} className="w-100 mt-10" />
                <AbsoluteTitle>Service<br />Delivery by<br /><span className="pink-color">Netomnia</span></AbsoluteTitle>
            </div>
            <DarkBg>
                <Row className="justify-content-center text-center">
                    <Col xl="5" className="flex-end pt-5 mt-5 first-img-wrapper">
                        <img src={serviceDeliveryAbout} className="w-100 first-img"/>
                    </Col>
                    <Col xl="7" className="flex-center pl-2 pb-5">
                        <StyledSubtitle className="white-color text-left w-70">
                            Setting the standard when it comes to
                            deliverability and getting your customers on
                            to our network is the key to unlocking access
                            to full fibre broadband across the UK.
                        </StyledSubtitle>
                        <Text className="white-color text-left w-70">
                            If you’re looking to expand your reach, our experts
                            are on hand to maintain and connect your customers
                            quickly, efficiently and with impeccable service.
                        </Text>
                    </Col>
                </Row>
            </DarkBg>
            <StyledSection className="pt-0">
                <Container>
                    <Row>
                        <Col md={12} className="text-center pt-5">
                            <Title className="pink-color mb-0">How We Complete Full Fibre Installations</Title>
                            <iframe className="ytPlayer removeHorizontalPadding" frameborder="0" allowfullscreen="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="YouFibre" src={'https://www.youtube.com/embed/W3j3vg3VnnM?autoplay=false&amp;playsinline=1&amp;start=1&amp;enablejsapi=1&amp;controls=0&amp;fs=0&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;disablekb=1&amp;playlist=W3j3vg3VnnM&amp;widgetid=1'}></iframe>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" className="text-center mt-5">
                            <Title className="pink-color">Service Delivery Principles</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4">
                            <Box>
                                <img src={serviceImage1} width='100%' alt="Your customers in mind"/>
                                <div className="box-wrapper">
                                    <Title variant="small" className="mt-2 mb-2">
                                        Your customers in mind
                                    </Title>
                                    <img src={divider} width="35%" className="mb-2"/>
                                    <SmallParagraph>
                                        We offer a full turnkey product
                                        from inception to connection;
                                        network maintenance to home
                                        installations.
                                    </SmallParagraph>
                                    <SmallParagraph>
                                        Providing end-to-end solutions
                                        allows us to holistically provide a
                                        service that your customers will
                                        love.
                                    </SmallParagraph>
                                </div>
                            </Box>
                        </Col>
                        <Col lg="4">
                            <Box>
                                <img src={serviceImage2} width='100%' alt="Aid in Brand Reputation"/>
                                <div className="box-wrapper">
                                    <Title variant="small" className="mt-2 mb-2">
                                        Aid in Brand Reputation
                                    </Title>
                                    <img src={divider} width="35%" className="mb-2"/>
                                    <SmallParagraph>
                                        By offering a brilliant installation
                                        experience and a smooth transition
                                        to a new fibre network, it aids in
                                        your brand reputation and makes
                                        future customer acquisition that
                                        much easier.
                                    </SmallParagraph>
                                </div>
                            </Box>
                        </Col>
                        <Col lg="4">
                            <Box>
                                <img src={serviceImage3} width='100%' alt="Dedicated Partners"/>
                                <div className="box-wrapper">
                                    <Title variant="small" className="mt-2 mb-2">
                                        Dedicated Partners
                                    </Title>
                                    <img src={divider} width="35%" className="mb-2"/>
                                    <SmallParagraph>
                                        Having dedicated partners on
                                        board that share our mission has
                                        allowed us to rapidly scale and
                                        continue to grow the network
                                        month-on-month. 
                                    </SmallParagraph>
                                </div>
                            </Box>
                        </Col>
                        <Col lg="4">
                            <Box>
                                <img src={serviceImage4} width='100%' alt="Maximising RFT"/>
                                <div className="box-wrapper">
                                    <Title variant="small" className="mt-2 mb-2">
                                        Maximising RFT
                                    </Title>
                                    <img src={divider} width="35%" className="mb-2"/>
                                    <SmallParagraph>
                                        Prioritising maximum RFT enables
                                        us to quickly connect and grow your
                                        customer base that boosts your
                                        bottom line quicker.
                                    </SmallParagraph>
                                </div>
                            </Box>
                        </Col>
                        <Col lg="4">
                            <Box>
                                <img src={serviceImage5} width='100%' alt="Collaboration"/>
                                <div className="box-wrapper">
                                    <Title variant="small" className="mt-2 mb-2">
                                        Collaboration
                                    </Title>
                                    <img src={divider} width="35%" className="mb-2"/>
                                    <SmallParagraph>
                                        Operating at such a rapid pace
                                        means that communication is critical
                                        to ensuring consistent success.
                                    </SmallParagraph>
                                    <SmallParagraph>
                                        We closely collaborate on methods
                                        to amplify revenue and ensure
                                        barriers are overcome quickly by
                                        creating processes that minimise
                                        disruption.
                                    </SmallParagraph>
                                    <SmallParagraph>
                                        We do the hard work so that your
                                        customer facing teams can focus on
                                        acquisition and nurturing without
                                        having to worry about the technical
                                        details.
                                    </SmallParagraph>
                                </div>
                            </Box>
                        </Col>
                        <Col lg="4">
                            <Box>
                                <img src={serviceImage6} width='100%' alt="Detailed Reporting"/>
                                <div className="box-wrapper">
                                    <Title variant="small" className="mt-2 mb-2">
                                        Detailed Reporting
                                    </Title>
                                    <img src={divider} width="35%" className="mb-2"/>
                                    <SmallParagraph>
                                        Analysing the numbers about our
                                        performance helps us make
                                        appropriate tweaks and boosts our
                                        expansion further. Reaching more
                                        premises’ unlocks further revenue
                                        opportunities for you.
                                    </SmallParagraph>
                                </div>
                            </Box>
                        </Col>
                    </Row>
                    <Row className="mt-5 pt-5">
                        <Col lg="12" className="text-center">
                            <Title className="pink-color">Key Features</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" className="text-center">
                            <img src={serviceIcon1} height="80px" alt="Detailed Reporting"/>
                            <Title variant="small" className="mt-3 mb-2">
                                Detailed Reporting
                            </Title>
                            <img src={divider} className="mb-2" width="25%"/>
                            <SmallParagraph>
                                We are committed to respond to incidents within 24 hours of the problem being reported.
                                We understand the importance of resolving issues as quickly as possible to ensure your
                                customers feel heard and well looked after.
                            </SmallParagraph>
                        </Col>
                        <Col lg="4" className="text-center">
                            <img src={serviceIcon2} height="80px" alt="Detailed Reporting"/>
                            <Title variant="small" className="mt-3 mb-2">
                                Dedicated Customer Operations Contact
                            </Title>
                            <img src={divider} className="mb-2" width="25%"/>
                            <SmallParagraph>
                                Communication is the key to operating a business. As an extension of your company,
                                you will have a dedicated contact at Netomnia that will aid with any query you have.
                            </SmallParagraph>
                        </Col>
                        <Col lg="4" className="text-center">
                            <img src={serviceIcon3} height="80px" alt="Detailed Reporting"/>
                            <Title variant="small" className="mt-3 mb-2">
                                Integrated Partner Onboarding
                            </Title>
                            <img src={divider} className="mb-2" width="25%"/>
                            <SmallParagraph>
                                Once you are onboarded, you will be able to access our entire network footprint.
                                Orders can be placed immediately, and your business will start to see growth.
                            </SmallParagraph>
                        </Col>
                    </Row>
                </Container>
            </StyledSection>
            <PageWrapper footerDark>
                <GradientSection>
                    <Container>
                        <Row>
                            <AbsoluteImage src={bottomMotive} />
                            <Col lg="6">
                                <Title variant="news">Dedicated Teams…</Title>
                                <Text>
                                    Our dedicated teams are here to support your business growth and
                                    help increase your bottom line.
                                    Whatever your business needs, we have a network for everything.
                                    If you are wanting to learn more about our network, please do get in
                                    touch and we will respond as soon as possible!
                                </Text>
                            </Col>
                            <Col lg="6" className="align-top-end">
                                <Link to="/contact/">
                                    <OutlinedLightButton>Contact us</OutlinedLightButton>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>
            </PageWrapper>
        </div>
    )
}

export default ServiceDeliveryPage;
