import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Text, Button } from "../../components/Core";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";
import YouFibreLogo from "../../assets/image/svg/yf-logo.svg"
import { navigate } from 'gatsby';

import iconPremises from "../../assets/image/rebrand/icon-1000000-premises.svg";
import iconExchange from "../../assets/image/rebrand/icon-100-exchange.svg";
import iconPartner from "../../assets/image/rebrand/icon-partner.svg";

const GlobalStats = () => {

    const FeatureTitle = styled(Text)`
        font-size: 1.3em;
        line-height: 1.2;
        color:black;
        font-weight: bold;
        display: block;
    `;

    const data = useStaticQuery(graphql`
    query getGlobalStats{
      strapiGlobalStats {
        stat_1_title
        stat_1_description
        stat_2_title
        stat_2_description
        stat_3_title
        stat_3_description
        stat_partner_description
      }
    }`)

    const {
        stat_1_title,
        stat_1_description,
        stat_2_title,
        stat_2_description,
        stat_3_title,
        stat_3_description,
        stat_partner_description
    } = data.strapiGlobalStats

    return (
        <Container>
            <Row>
                <Col lg={6} className="text-left">
                    <div className="flex mb-3">
                        <img src={iconPremises} alt="" className="mr-3" width={80}/>
                        <div className="align-self-center">
                            <FeatureTitle>1,000,000 premises<br />ready for service</FeatureTitle>
                        </div>
                    </div>
                    <div className="flex mb-3">
                        <img src={iconExchange} alt="" className="mr-3" width={80}/>
                        <div className="align-self-center">
                            <FeatureTitle>Across 100 exchange areas</FeatureTitle>
                            <Text>{stat_2_description}</Text>
                        </div>
                    </div>
                    <div className="flex mb-5">
                        <img src={iconPartner} alt="" className="mr-3" width={80}/>
                        <div className="align-self-center">
                            <FeatureTitle>1 Partner</FeatureTitle>
                            <img alt="" src={YouFibreLogo} className="mb-1" width='auto' height={24} />
                        </div>
                    </div>
                </Col>
                <Col lg={6} className="text-center align-self-center">
                    <Text className="mb-5">{stat_partner_description}</Text>
                    <Link to="/contact/">
                        <Button
                            style={{
                                background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                border: 'none',
                                width: 'auto'
                            }}
                            className="btn-red hvr-bounce-to-left"
                            onClick={() => navigate('/check-availability/')}
                        >
                            Become a Partner
                        </Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default GlobalStats;
